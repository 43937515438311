import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import "../../assets/css/customer/bot.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import download from "../../assets/images/icons/download.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
// import SendIcon from "../../assets/images/icons/send.png";
import SendIcon from "../../assets/images/icons/send1.png";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
// import Header from '../../components/Header';
import ChatHeader from '../../components/ChatHeader';
import { apiCall } from "../../services/apiservices";
import getCurrentIST from '../../services/currentDateTime';
import { useUIContext } from "../../context";
import ArrowDown from '../../assets/images/icons/arrow-down.png';
import PdfIcon from '../../assets/images/icons/pdf.png';
import ExcelIcon from '../../assets/images/icons/excel.png';
import Imageicon from '../../assets/images/icons/image-icon.png';
import Videoicon from '../../assets/images/icons/video_icon.png';
import SetLoadingScreen from "../../components/SetLoader";
import CloseIcon from '@mui/icons-material/Close';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavigationRounded } from '@mui/icons-material';
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';

const Message = ({ text, sender, timestamp, read, media_url, media_type, media_name, senderId, onlineStatus, openModal, downloadBtn }) => (
  <div className={sender === senderId ? 'BotUserMessage' : 'BotMessage'}>
    {text && <div className='BotMessageText Word_breack'>{text}</div>}
    {media_url && media_type && (
      <div className="file-preview">
        {media_type.match(/(jpeg|jpg|gif|png)$/) && (
          <div className="media-item">
            <div className="preview-container preview-container-image medio_icon"  >
              <img src={Imageicon} alt="preview" onClick={() => openModal(media_url, media_type)} />
            </div>
            <div className="download-btn">
              <p>{media_name}</p>
              <img src={download} alt="arrow" onClick={() => downloadBtn(media_url)} />
            </div>
          </div>
        )}

        {media_type.match(/(mp4|ogg)$/) && (
          <div className="media-item">
            <div className="preview-container preview-container-video medio_icon">
              <img src={Videoicon} alt="preview" onClick={() => openModal(media_url, media_type)} />
            </div>
            <div className="download-btn">
              <p>{media_name}</p>
              <img src={download} alt="arrow" onClick={() => downloadBtn(media_url)} />
            </div>
          </div>
        )}

        {media_type.match(/pdf$/) && (
          <div className="media-item">
            <div className="preview-container preview-container-pdf medio_icon">
              <img src={PdfIcon} alt="pdficon" className='bot_media_icon' />
            </div>
            <div className="download-btn">
              <p>{media_name}</p>
              <img src={download} alt="arrow" onClick={() => downloadBtn(media_url)} />
            </div>
          </div>
        )}

        {/* show audio */}
        {media_type.match(/audio\/webm/) && (
          <div className="preview-container preview-container-video medio_icon">
            {/* <AudioPlayer src={media_url} /> */}
            <audio controls src={media_url}></audio>
          </div>
        )}
        
        {media_type.match(/application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet|application\/vnd\.ms-excel|text\/csv/) && (
          <div className="media-item">
            <div className="preview-container preview-container-pdf medio_icon">
              <img src={ExcelIcon} alt="excelIcon" className='bot_media_icon' />
            </div>
            <div className="download-btn">
              <p>{media_name}</p>
              <div>
                <img src={download} alt="arrow" onClick={() => downloadBtn(media_url)} />
              </div>
            </div>
          </div>
        )}

      </div>
    )}

    <div className='BotMessageFooter'>
      <span className='BotTimestamp'>{timestamp}</span>
      {sender === senderId ? (
        onlineStatus === 'Online' ? (
          <div className='BotCheckIcons'>
            <DoneAllIcon className='BotCheckIcon' />
          </div>
        ) : read === '1' ? (
          <div className='BotCheckIcons'>
            <DoneAllIcon className='BotCheckIcon' />
          </div>
        ) : (
          <div className='BotCheckIcons'>
            <DoneIcon className='BotCheckIcon' />
          </div>
        )
      ) : null}
    </div>
  </div>
);


const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  // const [audioBlob, setAudioBlob] = useState(null);
  const [openFilePreview, setOpenFilePreview] = useState(false);
  const chatWindowRef = useRef(null);
  const location = useLocation();
  const chatId = location.state?.id;
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const senderId = JSON.parse(localStorage.getItem('user-cred')).user.customer_id;
  const { loading, setloading } = useUIContext();
  const currentTime = getCurrentIST();
  const [isTyping, setIsTyping] = useState(false);
  const { typingStatus, setTypingStatus} = useUIContext();
  const [ onlineStatus, setOnlineStatus] = useState('Offline');
  const [msgReadStatus, setMsgReadStatus] = useState('0');

  const [openmodel, setopenmodel] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloadBtnValue, setDownloadBtnValue] = useState('')
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [messageLoading, setMessageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();
  const employeeName = location.state?.employeeName || 'Chat';

  // new changes for voice messaging
  const [audioRecordingState, setAudioRecordingState] = useState({
    isRecording: false,
    audioBlob: null,
    mediaRecorder: null,
    audioChunks: []
  });

  const convertUrlTobase64 = async (url) => {
    const endpoint = `chat/convertUrlTobase64`;
    const payload = { 'url': url }
    let response = await apiCall({ endpoint: endpoint, method: 'POST', payload });
    return response.base64
  }

  const downloadBtn = async (url) => {
    let urlBase64String = await convertUrlTobase64(url)
    setDownloadBtnValue(urlBase64String);
    const link = document.createElement('a');
    link.href = urlBase64String;
    link.download = 'Document';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  const openModal = async (url, media_type) => {
    setIsLoading(true)

    let urlBase64String = await convertUrlTobase64(url)
    if (urlBase64String) {
      setIsLoading(false)
      setMediaUrl(urlBase64String);
      setMediaType(media_type);
      setopenmodel(true);
    } else {
      setIsLoading(false)
      toast.error("Fail to open document");
      console.error("Failed to convert URL to Base64, modal will not open.");
    }
  };

  const closeModal = () => {
    setopenmodel(false);
    setMediaUrl("");
  };

  // function to get chat module
  const fetchConversation = async () => {
    if (!hasMore) return;
    try {
      setMessageLoading(true);
      const endpoint = `chat/getChatData?receiverId=${chatId}&page=${page}`;
      let response = await apiCall({ endpoint: endpoint, method: 'GET' });
      if (response.data.length === 0) {
        setHasMore(false);
        return;
      }

      response = [...response.data, ...messages]
      let groupedMessages = response.map((dayData) => ({
        date: dayData.date,
        chats: dayData.chats
      }));
      const groupedData = groupedMessages.reduce((acc, { date, chats }) => {
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date] = [...acc[date], ...chats];
        return acc;
      }, {});

      const finalData = Object.keys(groupedData).map(date => ({
        date,
        chats: groupedData[date]
      }));
      setMessages(finalData);
    } catch (error) {
      console.error('Error fetching chat data:', error);
    } finally {
      setMessageLoading(false);
    }
  };

  const fetchConversationWithScroll = async () => {
    const chatWindow = chatWindowRef.current;
    const prevScrollHeight = chatWindow.scrollHeight;

    await fetchConversation();
    setTimeout(() => {
      const newScrollHeight = chatWindow.scrollHeight;
      chatWindow.scrollTop = newScrollHeight - prevScrollHeight;
    }, 0);
  };

  // handle scroll
  const handleScroll = () => {
    if (chatWindowRef.current.scrollTop === 0 && !messageLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };


  useEffect(() => {
    if (!chatId) {
      navigate("/employe/chatlist");
      return;
    }
    // mark messages as read
    const markMessageRead = async () => {
      try {
        const endpoint = `chat/markMessagesAsRead`;
        const payload = {
          'senderId': senderId,
          'receiverId': chatId
        }
        await apiCall({ endpoint: endpoint, method: 'POST', payload });
      } catch (error) {
        console.log('Failed to read messages: ', error);
      }
    }

    markMessageRead();

    // socket.io initialization
    socketRef.current = io('https://manthanapp.in');
    // socketRef.current = io(process.env.REACT_APP_API_URL);

    socketRef.current.on('connect', () => {
      console.log('Connected to Socket.IO server');
      socketRef.current.emit('connect_user', senderId);
    });

    socketRef.current.on('update_user_status', ({ userId, status, users }) => {
      if (users && users.hasOwnProperty(senderId) && users.hasOwnProperty(chatId)) {
        setOnlineStatus('Online');
        setMsgReadStatus('1');
        setMessages((prevMessages) =>
          prevMessages.map((msg) => ({
            ...msg,
            chats: msg.chats.map((chat) => ({
              ...chat,
              read_status: '1'
            })),
          }))
        );
      } else {
        setOnlineStatus('Offline')
        setMsgReadStatus('0');
      }
    });

    // SUBSTITUTE CODE
    socketRef.current.on('receive_message', (message) => {
      if (message.sender === chatId) {
        setMessages((prevMessages) => {
          const hasTodayDate = prevMessages.some(msg => msg.date === currentTime.todayDate);

          return [
            ...prevMessages,
            ...(hasTodayDate ? [] : [{ date: currentTime.todayDate, chats: [] }]),
            {
              chats: [{
                message: message.content,
                sender_id: message.sender,
                timestamp: currentTime.currTime,
                read_status: true,
                media_url: message.media_url || null,
                media_type: message.media_type || null,
                media_name: message.media_name || null
              }]
            },
          ];
        });
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);


  useEffect(() => {
    if (page > 1) {
      fetchConversationWithScroll();
    } else {
      fetchConversation();
    }

    socketRef.current.on("typing_status", ({ sender, isTyping }) => {
      if (sender === chatId) setTypingStatus(isTyping ? "Typing..." : "");
    });
  }, [chatId, page]);


  // ========================= MESSAGE SEND ======================
  const sendMessage = async (audioBlob) => {
    setloading(true);
    const formData = new FormData();
    formData.append("recipientId", chatId);
    formData.append("message", input);
    formData.append("readStatus", msgReadStatus);

    if (file && file instanceof File) {
      formData.append('file', file);
    }

    if(audioBlob && audioBlob instanceof Blob){
      formData.append('audio', audioBlob, 'voice_message.webm');
    }

    if (input.trim() || file || audioBlob) {
      try {
        if (file || audioBlob) {
          setIsLoading(true)
        }
        const endpoint = `chat/storeMessages`;
        const response = await apiCall({ endpoint, method: 'POST', payload: formData });
        if (!response.success) {
          setIsLoading(false)
          setOpenFilePreview(false)
          toast.error("Fail to send message");
          return;
        }
        let mediaUrl = response?.media_url || null;
        let mediaName = response?.media_name || null;
        let mediaType = response?.media_type || null;
        const messageToSend = {
          sender: senderId,
          recipient: chatId,
          content: input,
          media_url: mediaUrl,
          media_name: mediaName,
          media_type: mediaType

        };
        socketRef.current.emit('send_message', messageToSend);

        const hasTodayDate = messages.some(msg => msg.date === currentTime.todayDate);
        setMessages((prevMessages) => [
          ...prevMessages,
          ...(!hasTodayDate ? [{ date: currentTime.todayDate, chats: [] }] : []),
          { chats: [{ message: input, sender_id: senderId, timestamp: currentTime.currTime, read_status: msgReadStatus, media_url: mediaUrl, media_name: mediaName, media_type: mediaType }] }
        ]);
        setInput('');
        setFile(null);
        setAudioRecordingState({
          isRecording: false,
          audioBlob: null,
          mediaRecorder: null,
          audioChunks: []
        })
        setOpenFilePreview(false);
        fileInputRef.current.value = null;
      } catch (error) {
        setIsLoading(false);
        setOpenFilePreview(false)
        console.log('Error on sending message:', error);
        toast.error("Network error: Failed to send message");
      } finally {
        setIsLoading(false)
        setloading(false);
        setOpenFilePreview(false)
      }
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    sendMessage();
  };

  // handle input change
  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (!isTyping) {
      setIsTyping(true);
      socketRef.current.emit('typing', { recipient: chatId, isTyping: true });
      setTimeout(() => {
        setIsTyping(false);
        socketRef.current.emit('typing', { recipient: chatId, isTyping: false });
      }, 1000);
    }
  };

  const handleUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setOpenFilePreview(true);
    } else{
      toast.error('No file is selected');
    }
  };



  useEffect(() => {
    const chatContainer = chatWindowRef.current;

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [messageLoading, hasMore]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [input]);

  // handle recording function
  const startRecording = async () => {
    try{
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm'
      });
      const audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        if(event.data.size > 0){
          audioChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, {type: 'audio/webm'});

        setAudioRecordingState(prev => ({
          ...prev,
          isRecording: false,
          audioBlob,
          audioChunks: []
        }));

        if(audioBlob){
          sendMessage(audioBlob);
        }

        stream.getTracks().forEach(track => track.stop());
      };

      mediaRecorder.start();
      setAudioRecordingState(prev => ({
        ...prev,
        isRecording: true,
        mediaRecorder,
        audioChunks: []
      }));
    }catch(error){
      console.log('Error on accessing microphone: ', error);
      toast.error('Failed to access microphone.');
    }
  }

  const stopRecording = () => {
    const {mediaRecorder} = audioRecordingState;
    if(mediaRecorder && mediaRecorder.state !== 'inactive'){
      mediaRecorder.stop();
    }
  }

  const handleVoiceMessageToggle = () => {
    const {isRecording} = audioRecordingState;
    if(isRecording){
      stopRecording();
    } else{
      startRecording();
    }
  }
  
  return (
    <>
      <SetLoadingScreen loading={isLoading} />
      {/* <Header title={employeeName} arrowIcon={arrowIcon} /> */}
      <ChatHeader title={employeeName} arrowIcon={arrowIcon} onlineStatus={onlineStatus}/>
      <div className="ContentContainer BotChatApp">
        <div ref={chatWindowRef} style={{
          height: '500px',
          overflowY: 'scroll'
        }} className='BotChatWindow' >
          {messageLoading && <p className='text-center'>Loading...</p>}
          {messages.map((dayData, index) => (
            <React.Fragment key={index}>
              {dayData.date ? (
                <div className="date-header">
                  <span>{dayData.date}</span>
                </div>) : ''}
              {(dayData.chats || []).map((msg, msgIndex) => (
                <Message
                  key={msgIndex}
                  {...msg}
                  text={msg.message}
                  sender={msg.sender_id}
                  timestamp={msg.timestamp}
                  read={msg.read_status}
                  media_url={msg.media_url}
                  media_type={msg.media_type}
                  media_name={msg.media_name}
                  senderId={senderId}
                  onlineStatus={onlineStatus}
                  openModal={openModal}
                  downloadBtn={downloadBtn}
                />
              ))}
            </React.Fragment>
          ))}
        </div>

        <form onSubmit={handleSendMessage} className='BotMessageInput'>
          <label htmlFor="file-upload" className='BotUploadLabel'>
            <AttachFileIcon className='BotIcon' />
          </label>
          <input
            id="file-upload"
            type="file"
            className='BotFileInput'
            onChange={handleUpload}
            ref={fileInputRef}
          />
          <MicIcon
            className='BotIcon'
            onClick={handleVoiceMessageToggle}
            style={{ color: audioRecordingState.isRecording ? 'red' : 'black' }}
          />
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className='BotInput'
          />
          <button type="submit" className='BotSendButton'>
            <img src={SendIcon} alt="icon" className='BotSendIcon' />
          </button>
        </form>
      </div>

      <div className={`chat_modal ${openFilePreview ? 'open' : ''}`}>
        <div className="chat_modal_overlay" onClick={() => setOpenFilePreview(false)}>
          <div className="chat_modal_content">
            <div className="chat_modal_header">
              <h3>File Preview</h3>
              <CloseIcon onClick={() => setOpenFilePreview(false)} />
            </div>
            <div className="chat_modal_body">
              {file && (file.type.startsWith('image/') ? (
                <img src={URL.createObjectURL(file)} alt="Preview" />
              ) : (
                <p>{file.name}</p>
              ))}
            </div>
            <div className="chat_modal_footer">
              <button
                onClick={sendMessage}
                className={`send-btn ${loading ? 'disabled' : ''}`}
                disabled={loading}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>

      {openmodel && (

        <div className="chat_modal_overlay2">
          <div className=" chat_modal_content2">
            <div className="chat_modal_header2">
              <h3>File Preview</h3>
              <CloseIcon onClick={closeModal} />
            </div>

            <div className="chat_modal_body2">
              {mediaType.match(/(jpeg|jpg|png)$/) && (
                <img src={mediaUrl} alt="preview" />
              )}

              {mediaType.match(/(mp4|webm|ogg)$/) && (
                <video controls >
                  <source src={mediaUrl} type={`${mediaType}`} />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Chat;
