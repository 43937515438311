import React from 'react'
import '../../assets/css/employe/employehome.css'
import profileICon from '../../assets/images/icons/profile.png'
import requestIcon from '../../assets/images/icons/request.png'
import trackIcon from '../../assets/images/icons/track.png'
import linkIcon from '../../assets/images/icons/link.png'
import Footer from '../../components/Footer.jsx'
import { Link } from 'react-router-dom'
import Header from '../../components/Header.jsx'
import { useState,useEffect } from 'react'
import {jwtDecode} from 'jwt-decode'
import DashboardCards from '../../components/DashboardCard.jsx'
import ticketIcon from "../../assets/images/icons/ticket.png";
import { apiCall } from '../../services/apiservices.jsx'
import performanceIcon from "../../assets/images/icons/performance.png";
import callIcon from '../../assets/images/icons/call.png';
import targetIcon from '../../assets/images/icons/target.png';
import chatIcon from "../../assets/images/icons/chat.png";


const EmployeHome = () => {
    const buttons = [
        { path: '/employe/accountlink', icon: linkIcon, label: 'Account Link' },
        { path: '/employe/trackmessage', icon: trackIcon, label: 'Track' },
        { path: '/employee/In-Process', icon: requestIcon, label: 'To-Do' },
        { path: '/employe/target', icon: targetIcon, label: 'Target' },
        { path: '/employe/performance', icon: performanceIcon, label: 'Performance' },
        { path: '/contact', icon: callIcon, label: 'Contact Us' },
      ];

      
  const [customerName, setCustomerName] = useState('');
  const [pendingCount, setPendingCount] = useState(0);
  const [inProcessCount, setInProcessCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState();

  useEffect(() => {
    const token = localStorage.getItem('user-cred');

    if (token) {
      try {
        const parsedToken = JSON.parse(token); 
        setCustomerName(parsedToken.user.customer_name || 'Guest');
      } catch (error) {
        console.error("Error decoding token:", error);
        setCustomerName('Guest');
      }
    }
  }, []);


  useEffect(() => {
    const getDashboardDataForEmployee = async () => {
        try {
            const res = await apiCall({ endpoint: 'employee/dashboardDataForEmployee', method: 'GET' });
            if (res.success) {
                setPendingCount(res.data.pendingCount);
                setInProcessCount(res.data.inProcessCount);
                setResolvedCount(res.data.Resolved);
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    const getUnreadMessageCount = async () => {
        try {
            const res = await apiCall({ endpoint: 'employee/getMessageCount', method: 'GET' });
            if (res.success) {
                setUnreadCount(res.data.totalMessageCount);
            }
        } catch (error) {
            console.error('Error fetching message count:', error);
        }
    };

    getDashboardDataForEmployee();
    getUnreadMessageCount();

    const dashboardInterval = setInterval(getDashboardDataForEmployee, 30000);
    const messageInterval = setInterval(getUnreadMessageCount, 30000);

    return () => {
        clearInterval(dashboardInterval);
        clearInterval(messageInterval);
    };
}, []);

  

  const ticketData = [
    { count: `${pendingCount}`, label: 'Pending', className: 'pending' },
    { count: `${inProcessCount}`, label: 'In-Process', className: ' ' },
    { count: `${resolvedCount}`, label: 'Resolved', className: ' resolve' },
];

  return (
    <>
    <Header title={customerName} arrowIcon={profileICon} profileIconClass="d-none"/>


    <div className="ContentContainer">


    <DashboardCards title="To-Do Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} />

        
        <div className="BtnContain">
          <div className="row gy-4">
            {buttons.map((button, index) => (
              <div key={index} className={`col-4 ${index % 3 === 0 ? 'ps-0' : ''} ${index % 3 === 2 ? 'pe-0' : ''}`}>
                <Link to={button.path}>
                  <div className={`IconBtn ${index % 3 === 0 ? 'me-auto' : ''} ${index % 3 === 2 ? 'ms-auto' : ''}${index % 3 === 1 ? 'm-auto' : ''} `}>
                    <div><img src={button.icon} alt="icon" /></div>
                    <span>{button.label}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

    </div>
    {/* <Footer/> */}
    <Footer
        modifiedItem={{
          index: 2,
          icon: chatIcon,
          label: "Chat",
          link: "/employe/chatPreview",
          conditionalUrl: '/employe/home',
          number:unreadCount,
        }}
      />


    </>
  )
}



export default EmployeHome
