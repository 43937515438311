import React, { useEffect, useState } from 'react';
import '../assets/css/components/header.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import close from "../assets/images/icons/close.png";
import ProfileIcon from "../assets/images/icons/profile1.png";
import { useUIContext } from '../context';


const ChatHeader = ({ title, arrowIcon, onlineStatus, profileIconClass }) => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { setHome, SelectImage, chatUserProfile, setChatUserProfile, typingStatus, setTypingStatus} = useUIContext();
    const { id } = useParams();
    const user = chatUserProfile ? chatUserProfile.find(user => user.id === id) : null;
    // const [userChatStatus, setUserChatStatus] = useState(onlineStatus);




    useEffect(() => {
        if (location.pathname.includes("/admin")) {
            setHome("/admin/home");
        } else if (location.pathname.includes("/employe")) {
            setHome('/employe/home');
        } else {
            setHome('/home');
        }
    }, [location.pathname, setHome]);

    const handleClick = (event) => {
        const validHomes = ['/home', '/admin/home', '/employe/home'];
        if (validHomes.includes(location.pathname)) {
            event.preventDefault();
            setOpen(!open);
        } else {
            navigate(-1);
        }
    };

    return (
        <section id="Header" className="ContactHeader">
            <div className='profile_text'>
                <div className={profileIconClass === 'd-none' ? 'HeaderContain' : "HeaderContain ProfileHeader"}>
                    <div className={profileIconClass === 'd-none' ? 'ProfileIcon' : "ProfileIcon bg_transparant"}>
                        <Link onClick={handleClick}>
                            <img src={location.pathname === "/home" || location.pathname === "/admin/home" || location.pathname === "/employe/home" ? SelectImage : arrowIcon} alt="Back" />
                        </Link>
                    </div>

                    <h2 className="HeaderTitle">{profileIconClass === 'd-none' ? 'Hi, ' : " "}{title}</h2>
                </div>
                

                <div className={`ProfileIcon ${profileIconClass || ''}`} >
                    <Link to={`/chatProfile/${id}`}>
                    <img src={user?.userProfile && user.userProfile !== '' ? user.userProfile : ProfileIcon} alt="Profile" />
                    </Link>
                </div>
                
            </div>

            <div className="status mb-5">
                <p className="typing-status set_status">
                    {typingStatus === 'Typing...' ? 'Typing...' : (onlineStatus === 'Online' ? 'Online' : '')}
                </p>
            </div>
        </section>
    );
};

export default ChatHeader;
