import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/css/components/footer.css';
import homeIcon from '../assets/images/icons/home1.png';
import Profile1Icon from "../assets/images/icons/profile1.png";
import botIcon from '../assets/images/icons/bot.png';
import chatIcon from "../assets/images/icons/chat.png";
import { useUIContext } from '../context';

const Footer = ({ modifiedItem }) => {
    const { home } = useUIContext();
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);

    let originalFooterItems = [
        { icon: homeIcon, label: 'Home', link: home },
        { icon: Profile1Icon, label: 'Profile', link: '/profile' },
    ];

    // // Conditional item
    if (home.includes('/admin') || home.includes('/home')) {
        originalFooterItems.push({ icon: chatIcon, label: 'Chat', link: '/employe/chatPreview' });
    }
    // else {
    //     originalFooterItems.push({ icon: botIcon, label: 'AI Bot', link: '/bot' });
    // }

    // Function to create modified footer items
    const createFooterItems = (modifiedItem) => {
        return originalFooterItems.map((item, index) => {
            if (index === modifiedItem.index) {
                return { ...item, icon: modifiedItem.icon, label: modifiedItem.label, link: modifiedItem.link, number: modifiedItem.number, conditionalUrl: modifiedItem.conditionalUrl };
            }
            return item;
        });
    };

    const items = modifiedItem ? createFooterItems(modifiedItem) : originalFooterItems;

    useEffect(() => {
        const currentPath = location.pathname;

        // Check if the modified item link matches the current path
        if (modifiedItem && modifiedItem.link === currentPath) {
            setActiveIndex(modifiedItem.index);
        } else {
            const activeItemIndex = items.findIndex(item => item.link === currentPath);
            setActiveIndex(activeItemIndex >= 0 ? activeItemIndex : 0); // Default to 0 if not found
        }
    }, [location.pathname, items, modifiedItem]);

    const handleClick = (index, event) => {
        if (activeIndex === index) {
            event.preventDefault(); // Prevent default behavior if already active
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div id="Footer">
            <div className="FooterContain">
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-4" key={index}>
                            <div className="Footer_card">
                                <Link to={item.link} onClick={(event) => handleClick(index, event)}>
                                    <div className={`Footer_cion ${activeIndex === index ? 'active' : ''}`}>
                                        <img src={item.icon} alt={item.label} />
                                        {item.conditionalUrl === '/employe/home' && (
                                            item.number === 0 ? (
                                                ''
                                            ) : <span className="chat_count chat_count1">{item.number}</span>
                                        )}
                                        <span>{item.label}</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Footer;
