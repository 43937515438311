import axios from 'axios';

// const baseURL = process.env.NODE_ENV === 'development'? 'https://manthanapp.in/api' : "https://manthanapp.in/api"
const baseURL = `https://manthanapp.in/api`

export const apiCall = async ({ endpoint, method = 'GET', payload = null }) => {
    try {
      const user = JSON.parse(localStorage.getItem('user-cred'));
      const defaultHeaders = {};
      if(user?.token){
        defaultHeaders['Authorization'] = `Bearer ${user?.token}`
      }

      // Adjust content-type based on payload type
    if (!(payload instanceof FormData)) {
      defaultHeaders['Content-Type'] = 'application/json'
    }

      const config = {
        url: `${baseURL}/${endpoint}`,
        method: method,
        headers: defaultHeaders,
      };

      // Check if payload is FormData, in which case Axios handles headers automatically
      if (payload && (method === 'POST' || method === 'post' || method.toLocaleUpperCase() === 'PUT' )) {
        config['data'] = payload;
      }

      const response = await axios(config);
      return response.data;
    } catch (error) {
      throw error
    }
};

